@import '../../styles/global.scss';
.header {
        position: fixed;
        width: 100%;
        padding: 24px 0px 24px 50px;
        background: white;
        padding-left: 0;
         display: flex;
        justify-content: space-between;
        gap: 25px;
         align-items: center;
        z-index: 2;
        @include mobile {
            display: none;
        }
        .container {
            display: flex;
            gap: 25px;
        }
        a {
            text-transform: uppercase;
            font-size: 16px;
            text-decoration: none;
            z-index: 2;
            font-weight: 400;
        }
        .logo {
            justify-self: flex-start;
            width: 42px;
            height: 29px;
            margin-left: 50px;
            cursor: pointer;
        }
      
        .activeLink {
            color: #FF4D01;
        }
        .social {
            display: flex;
            gap: 25px;
            margin-left: 25px;
            margin-right: 52px;
            img {
                height: 19px;
                width: 19px;
                cursor: pointer;
            }
        }
}