@import './global.scss';
.fixed {
    position: fixed;
    bottom: 50px;
    right: 30px;
    z-index: 999;
    @include mobile {
        bottom: 30px;
        right: 10px;
    }
    .linkSocial {
        display: flex;
        flex-direction: column;
        gap: 30px;
        @include mobile {
            gap: 15px;
        }
        a {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #FF4D01;
            cursor: pointer;
            @include mobile {
                width: 40px;
                height: 40px;
            }
        }
        .phoneIcon {
            color: white;
            @include mobile {
                width: 20px;
                height: 20px;
            }
        }
    }
    .tg {
        width: 30px;
        height: 30px;
        @include mobile {
            width: 15px;
            height: 15px;
        }
    }
}