@import "../../../styles/global.scss";
.container {
  // width: 81%;
  width: 100%;
  margin:  0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
   @include mobile {
    width: 90%;
   }
}
.ViciousCircle {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 25px;
  
}
.download {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #FF4D01;
  padding: 25px 0px 35px 0px;

  a {
    color: white;
    font-size: 50px;
    text-underline-offset:10px;
    @include mobile {
      font-size: 25px;
    }
  }
}
.fill {
  text-align: center;
  margin-top: 25px;
  font-size: 50px;
  color: #42525B;
  @include mobile {
    font-size: 25px;
  }
  span {
    color: #FF4D01;
  }
}
.header {
  margin-top: 25px;
background: #F6F6F6;
padding: 25px 0px;
@include mobile {
  padding: 20px 0px ;
}
  .title {
    font-weight: 400;
    font-size: 64px;
    text-align: center; 
    color: #FF4D01;
    @include mobile {
      font-size: 29px;
    }
  }
  ul {
    text-align: start;
    list-style: none;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    @include mobile {
      text-align: center;
      gap: 20px;
    }
    li {
      color: #42525B;
      font-size: 37px;
      &::before {
        content: "\2022";
        margin-right: 25px;
        width: 24px !important;
        height: 24px !important;
        color: #FF4D01;
        @include mobile {
          margin-right: 10px;
        }
      }
      @include mobile {
        font-size: 25px;
      }
    }
  }
}

.link {
  text-align: center;
  font-size: 20px;
  color: #FF4D01;
  @include mobile {
    margin-bottom: 10px;
  }
}
.text {
  font-weight: 400;
  font-size: 64px;
  text-align: center;
  color: #FF4D01;

  @include mobile{
    font-size: 30px;
  }
}

.title {
  font-weight: 400;
  font-size: 64px;
  text-align: center;
  color: #FF4D01;
  @include mobile{
    font-size: 29px;
  }
}

.btnWrap {
  display: flex;
  justify-content: center;
  margin: 60px 0;
}

.btn {
  text-transform: none;
  text-decoration: none;
  padding: 26px 40px;
  cursor: pointer;
  background: #FF4D01;
  color: #fff;
}