@import '../../../styles/global.scss';
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    margin-top: 25px;
    h2 {
        font-weight: 400;
        color: #42525B;
        font-size: 56px;
        @include mobile {
            font-size: 28px;
        }
    }
    ul {
        margin-left: 5%;
        @include mobile {
            margin-left: 10%;
        }
        li {
            color: #42525B;
            font-size: 26px;
            @include mobile {
                font-size: 20px;
            }
        }
    }
    a  {
        text-decoration: none;
        padding: 21px 24px;
        color: white;
        cursor: pointer;
        border-radius: 10px;
        background: #FF4D01;
        border: none;
        font-size: 20px;
        box-shadow: 0px 4px 4px 0px #00000040;

    }
}