@import '../../../styles/global.scss';
.title {
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 64px;
    color: #42525B;
    font-weight: 400;
    text-align: center;
    @include mobile {
        font-size: 32px;
    }
}
.container {
    display: flex;
    justify-content: center;
    gap: 20px;
    @include mobile {
        flex-direction: column;
    }
   .Card {
    width: 30%;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    @include mobile {
        gap: 15px;
        width: 100%;
    }
    &:nth-child(even) {
        background: #F6F6F6;
    }
    h2 {
        color: #42525B;
        font-weight: 400;
        font-size: 64px;
        @include mobile {
            font-size: 30px;
        }
    }
    .wrapper {
        display: flex;
        gap: 50px;
        flex-direction: column;
        align-items: center;
        &:nth-child(even) {
            flex-direction: column ;
        }
        @include mobile {
            gap: 20px;
            flex-direction: column;
            align-items: center;
            &:nth-child(even) {
                flex-direction: column ;
            }
        }
        .relative {
            position: relative;
            padding-bottom: 35px;
            height: 250px;
            @include mobile {
                height: auto;
            }
            .img {
                width: 355px;
                height: 237px;
               
                @include mobile {
                    width: 281px;
                    height: 181px;
                    background-size: 100% 100%;
                }
            }
           
        }
        button {
            border-radius: 10px;
            font-size: 20px;
            box-shadow: 0px 4px 4px 0px #00000040;

            padding: 21px 23px;
            height: 76px;
            color: white;
            border: none;
            cursor: pointer;
            background: #FF4D01;
           @include mobile {
            font-size: 15px;
            padding:  15px 18px ;
            height: 50px;
           }
            
        }
        a{
            border-radius: 10px;
            font-size: 20px;
            box-shadow: 0px 4px 4px 0px #00000040;

            padding: 24px 23px;
            height: 76px;
            color: white;
            border: none;
            cursor: pointer;
            background: #FF4D01;
            text-decoration: none;
            @include mobile {
                font-size: 15px;
                padding: 15px 18px;
                height: 50px;
            }
        }
        .price {
            display: flex;
            justify-content: space-between  ;
            align-items: center;
            width: 70%;
            
            p {
                font-size: 33px ;
                color: #FF4D01;

                @include mobile {
                    font-size: 30px;

                }
            }
            .oldPrice {
                position: relative;
                color: #D9D9D9;
               
            }
         
        }
    }
    .about {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 80%;
        height: 370px;
        @include mobile {
            height: auto;
        }
        h3 {
            color: #42525B;
            font-size: 45px;
            font-weight: 400;
            text-align: center;
            @include mobile {
                font-size: 30px;
            }
        }
        p {
            color: #FF4D01;
            font-size: 20px;
            text-align: center;
        }
        
        ul {
            display: flex;
            flex-direction: column;
            gap: 2px;
            li {
                color: #42525B;
                font-size: 20px;
            }
        }
    }
   }
 
}  
.bonus {
    height: 400px;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    @include mobile {
        height: auto;
        text-align: center;
        margin: 0 auto;
    }
    h3 {
        color: #FF4D01;
        font-size: 20px;
        font-weight: 400;
        @include mobile {
            font-size: 20px;
            font-weight: 400;
        }

    }
    ul {
        @include mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        li {
            color: #FF4D01;
            font-size: 20px;
            @include mobile {
                font-size: 20px;
            }
        }
    }
}
.oldPrice:before {
                
    border-bottom: 2px solid #B9B9B9;
    position: absolute;
    content: "";
    width: 100%;
    height: 50%;
    transform: rotate(12deg);

}
@media (min-width: 1200px) {
    .Card {
        width: 384px !important;
    }
}