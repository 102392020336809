@import '../../styles/global.scss';
.footer {
    padding: 25px 0px 56px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F7F7F7;
    gap: 21px;
    .social {
        display: flex;
        gap: 25px;
        // margin-left: 25px;
        img {
            height: 19px;
            width: 19px;
            cursor: pointer;
        }
        @include mobile {
            display: none;
        }
    }
    .security {
        display: flex;
        gap: 100px;
        margin-left: 45px;
        @include mobile {
            flex-direction: column;
            gap: 20px;
            margin-left: 0;
            text-align: center;
        }
        a {
            text-decoration: none;
        }
    }
}