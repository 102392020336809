@import '../../../styles/global.scss';

.info {
  width: 100%;
  padding: 27px 0px;
  background: #FF4D01;
  @include mobile {
    text-align: center;
    padding: 20px 50px;
  }
}
.container {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap:30px;
    @include mobile {
      margin-top: 50px;
    }
    
  .ussual {
    @include mobile {
      width: 90%;
      margin: 0 auto;
    }
    p {
        color: #42525B;
        font-size: 26px;
        line-height: 41.6px;
        @include mobile {
          font-size: 18px;
          line-height: unset;
          text-align: center;
        }
    }
  }
  button {
    border-radius: 10px;
    font-size: 26px;
    padding: 21px 26px;
    color: white;
    background: #FF4D01;
    cursor: pointer;
    border: none;
    box-shadow: 0px 4px 4px 0px #00000040;

    @include mobile {
      padding: 13px 19px;
      font-size: 20px;
    }
  }
   
}  
.main {
    color: white;
    font-size: 64px;
    @include mobile {
      font-size: 25px;
    }
}