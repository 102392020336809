@import "../../../styles/global.scss";
.breathMagician{
    padding-top: 100px;
    background: url("../../../../public/img/breathMagicianBg.png") 100% 100% no-repeat;
    background-size: cover;
    background-position: center;
    @include mobile {
        padding-top: 60px;
    }
}
.start {
    margin-top: 120px;
    font-size: 36px;
    height: 100px;
    @include mobile {
        margin-top: 40px;
        font-size: 18px;
        font-size: 26px;
        font-weight: 700;
    }
}
.wrap{
    padding: 20px 0;
    text-align: center;
    position: relative;
    .logo {
        position: absolute;
        z-index: -1;
        width: 440px;
        height: 305px;
        top: 17%;
        left: 33%;
        opacity: 0.35;
        @include mobile {
            width: 200px;
            height: 130px;
            left: 15%;
            top: 5%;
        }
    }
    p {
        z-index: 2;
    }
}

.subTitle{
    font-weight: 400;
    font-size: 40px;
    color: #42525B;
    @include mobile {
        font-size: 18px;
    }
}

.mainInfo {
    padding-top: 52px;
    z-index: 2;
    @include mobile {
        padding-top: 0px;
    }
    .title {
        font-weight: 700;
        font-size: 64px;
        text-transform: uppercase;
        color: #42525B;

        @include mobile{
            font-size: 50px;
            margin-bottom: 0px;
        }
    }
    .text{
        font-weight: 400;
        font-size: 36px;
        color: #42525B;

        @include mobile{
            font-size: 18px;
        }
    }
}
.btnWrap {
    display: flex;
    justify-content: center;
    margin-top: 100px;

    @include mobile{
        margin-top: 50px;
    }
    .btn {
        cursor: pointer;
        text-decoration: none;
        padding: 21px 23px;
        border-radius: 10px;
        font-size: 26px;
        height: 76px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FF4D01;
        color: #fff;
        font-weight: 400;
        font-size: 20px;
        @include mobile {
            padding: 15px 18px;
            font-size: 18px;
            height: 50px;
            @include mobile {
                border-radius: 6px;
            }
        }
    }
}