@import '../../../styles/global.scss';
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
    gap: 25px;
    h1 {
        font-weight: 400;
        font-size : 64px;
        color: #FF4D01;
        @include mobile {
            font-size: 30px;
            text-align: center;
        }
    }
    .video {
        width: 57%;
        .PlayCircle {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 10px 20px 0px #00000026;
            background: transparent;
            border: 3px solid white;
          
            position: relative;
           
            .PlayArrow {
              color: white;
              width: 61px;
              height: 61px;
             
            }
        }
        @include mobile {
            width: 95%;
        }
    }
}