@import '../../../styles/global.scss';
.container {
    padding: 25px 100px;
    margin-top: 50px;
    background: #F6F6F6;
    @include mobile {
        padding: 15px 20px;
    }
    .slide {
        min-height: 361px;
        @include mobile {
            min-height: 230px;
        } 
        img {
            object-fit: contain;
            width:  90%;
            height: 341px;
            @include mobile {
                width: 315px;
                height: 260px;
            } 
        }
    }
    .SlideContainer {
        margin: 25px auto;
    }
}

 .link {
    text-align: center;
    color: #FF4D01;
    font-size: 20px;
    margin-top: 25px;
 }
 .title {
        text-align: center;
        color: #42525B;
        font-size: 64px;
        font-weight: 400;
        margin-top: 25px;
        @include mobile {
            font-size: 35px;
        }
 }