@import '../../styles/global.scss';
.container {
    padding: 25px 100px;
    margin-top: 50px;
    background: #F6F6F6;
    @include mobile {
        padding: 15px 20px;
    }
    
}
.SlideContainer {
    // margin: 0 auto;
}

 .title {
        text-align: center;
        color: #42525B;
        font-size: 64px;
        font-weight: 400;
        margin-bottom: 25px;
        @include mobile {
            font-size: 35px;
        }
 }
 .video {
    width: 95% !important;
    height: 245px !important;
    overflow: hidden;
    border-radius: 0px 40px !important;
    background: transparent !important;
    @include mobile {
        width: 100% !important;
        height: 20% !important;
    }
    .PlayCircle {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 10px 20px 0px #00000026;
        background: transparent;
        border: 3px solid white;
        background-color: #FF4D01;
        position: relative;
       @include mobile {
        width: 40px;
        height: 40px;
       }
        .PlayArrow {
          color: white;
          width: 41px;
          height: 41px;
         @include mobile {
            width: 21px;
            height: 21px;
         }
        }
    }
}