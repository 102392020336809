@import "../../../styles/global.scss";
.expert{
  background-color: #fff;
}
.link {
  text-align: center;
  color: #FF4D01;
  font-size: 20px;
  margin-bottom: 25px;
  @include mobile {
    margin-bottom: 30px;
  }
}
.wrap{
  padding: 25px 0 10px;
  background: #F6F6F6;
  width: 100%;
}

.subTitle{
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  text-align: center;
  color: #FF4D01;
}

.title{
  font-weight: 400;
  font-size: 64px;
  line-height: 72.9%;
  text-align: center;
  color: #42525B;
  margin-top: 20px;
  margin-bottom: 20px;

  @include mobile{
    font-size: 34px;
    line-height: 44px;

  }
  span{
    color: #FF4D01;
  }
}

.secondTitle{
  font-weight: 400;
  font-size: 26px;
  color: #42525B;
  margin-bottom: 20px;
  text-align: center;

  @include mobile{
    font-size: 18px;
    text-align: start;
    width: 80%;
    margin: 0 auto;
  }

}

.expertContent{
  position: relative;
  width: 100%;
  background: url("../../../../public/img/expertBg.png") 100% 100% no-repeat;
  // background-size: cover;
  background-position: center;
  padding: 50px 0;
  margin: 0 auto;
  @include mobile {
    display: none;
  }

  .expertContentInfo{
    margin-bottom: 50px;
    width: 686px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 0px 50px;
    margin-left: 40%;
    padding: 30px 70px;
    @include mobile{
      width: 100%;
      margin-left: 0;
      padding: 15px 35px;
    }
    li{
      padding-top: 20px;
      font-weight: 400;
      font-size: 20px;
      color: #42525B;
      @include mobile{
        font-size: 18px;
      }
    }
  }
  .btn{
    padding: 26px 40px;
    position: absolute;
    background-color: #FF4D01;
    left: 63%;
    bottom: -30px;
    color: #fff;
    text-decoration: none;
    font-weight: 400;
    font-size: 20px;

    @include mobile{
      left: 30%;
    }
  }
}
.MobileExpert {
  display: none;  
  @include mobile {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

  }
  img {
    object-fit: contain;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
    list-style-type: disc ;
    li {
      font-size: 17px;
      color: #42525B;
      margin-left: 25px;
    }
  }
}
.anotherExpertContent {
  position: relative;
  width: 100%;
  background: url("../../../../public/img/anotherExpert.png") 100% 100% no-repeat;
  // background-size: cover;
  background-position: center;
  padding: 50px 0;
  margin: 0 auto;
  height: 650px;
  @include mobile {
    display: none;
  }

  .expertContentInfo{
    margin-bottom: 50px;
    width: 686px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 0px 50px;
    margin-left: 10%;
    padding: 30px 70px;
    @include mobile{
      width: 100%;
      margin-left: 0;
      padding: 15px 35px;
    }
    li{
      padding-top: 20px;
      font-weight: 400;
      font-size: 20px;
      color: #42525B;
      @include mobile{
        font-size: 18px;
      }
    }
  }
  .btn{
    padding: 26px 40px;
    position: absolute;
    background-color: #FF4D01;
    left: 63%;
    bottom: -30px;
    color: #fff;
    text-decoration: none;
    font-weight: 400;
    font-size: 20px;

    @include mobile{
      left: 30%;
    }
  }
}
.MobileExpert {
  display: none;  
  @include mobile {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

  }
  img {
    object-fit: contain;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style-type: disc ;
    li {
      font-size: 17px;
      color: #42525B;
      margin-left: 25px;
    }
  }
}