@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap');
@import './styles/global.scss';
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: 'PT Sans Narrow', sans-serif;
}
body {
    
    
}
p,a {
    color: #42525B;
}
ul {
  li {
    text-align: start;
  }
}
.container{
    width: 1250px;
    margin: 0 auto;
}
.react-player-review {
  width: 95% !important;
  height: 245px !important;
  overflow: hidden;
  border-radius: 0px 40px;
  @include mobile {
    width: 100% !important;
    height: calc(90vw - 170px) !important;
    
  }
  .react-player__preview {
    border-radius: 0px 40px;
    background-size: auto !important; 
    background-repeat: no-repeat !important;
    @include mobile {
      background-size: cover !important;
    }
  }
}
.react-player {
    width: 100% !important;
    height: 487px !important;
    overflow: hidden;
    box-shadow: 0px 0px 37.36000061035156px 0px #0000000D;
    border-radius: 0px 50px;
    @include mobile {
      
      height: 243px !important;
    }
    .react-player__preview {
      background-image: url('../public/img/video.png') !important;
      border-radius: 0px 50px;}
    }
@media screen and (max-width: 1250px){
  
    .container{
        width: 80vw;
    }
}
.dots-pag {
    margin-top: 0px;
    width: 30px;
    height: 30px;
    display: flex;
    background: white;
    
   @include mobile {
    margin-top: 10px;
    width: 15px;
    height: 15px;
   }
  
  }
  .slick-next, .slick-prev {
    display: none !important;
  }
  .slick-dots {
    position: initial !important;
    bottom: auto !important;
    display: flex !important;
    gap: 50px !important;
    color: white;
    justify-content: center;
    font-size: 32px;
    background: transparent;
    font-weight: 400;
    @include mobile {
      gap : 5px !important;
    }
  
  }
  .slick-dots {
    display: flex !important;
    flex-wrap: wrap;
    li {
      margin-bottom: 10px !important;
    }
    .slick-active {
      .activate {
        
        width: 30px;
        height: 30px;
       background: #FF4D01;
       margin-bottom: 10px;
        @include mobile {
          width: 15px;
          height: 15px;
        }
        
      }
  
    }
  }
  
  .slick-next {
    display: none !important;
  }
  .slick-track {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .links {
    text-align: center  ;
    color: #FF4D01;
    font-size: 20px;
    margin-top: 50px;
  }