@import '../../styles/global.scss';
.Modal {
    height: 100%;
    width: 100%;
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    z-index: 999;
    cursor: pointer;
  }
  
 
  .ModalContent {
    width: 600px;
    height:700px;
    background: white;
    text-align: center;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    transition: 0.4s all;
    background-repeat: no-repeat;
    cursor: auto;
    
    position: relative;
    @include mobile {
      width: 100%;
      height: 110vh;
    }
    .close {
      position: absolute;
      top: 50px;
      right: 30px;
      z-index: 9;
      cursor: pointer;
    }
    iframe {
        width: 100%;
        height: 1000px;
        border-radius: 20px;
        overflow: scroll;
        
    }
}