@import '../../styles/global.scss';
.Modal {
    height: 100%;
    width: 100%;
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    z-index: 999;
    cursor: pointer;
  }
  
 
  .ModalContent {
    width: 500px;
    height:650px;
    background: white;
    text-align: center;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: 0.4s all;
    background-repeat: no-repeat;
    cursor: auto;
    @include mobile {
        width: 300px;
        height: 400px;
    }
    .bg {
        width: 100%;
        height: 212px;
        background-image: url('../../../public/img/orderBG.svg');
        @include mobile {
            background-size: cover;
        }
        h3 {
            margin-top: 21px;
            font-weight: 700;
            font-size: 28px;
            color: #42525B;
            text-transform: uppercase;
            
        }
        p {
            margin-top: 15px;
            font-size: 17px;
            color: #42525B;
            @include mobile {
                font-size: 10px;
            }
        }
    }
    .form {
        padding: 25px 70px 50px 65px;
        @include mobile {
            padding: 10px 30px 20px 30px;
        }
        h3 {
            font-size: 24px;
            font-weight: 400;
            color: #42525B;
            @include mobile {
                font-size: 15px;
            }
        }
        form {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            gap: 25px;
            align-items: center;
            input {
                border: none;
                font-size: 24px;
                padding-left: 10px;
                width: 100%;
                border-bottom: 1px solid #FF4D01;
               color: #42525B;
                &:focus {
                    outline: none;
                }
            }button {
                margin-top: 25px;
                padding: 26px 38px;
                border : none;
                color:white;
                cursor: pointer;
                background: #FF4D01;
                @include mobile {
                    margin-top: 6px ;
                    padding: 13px 19px;
                }
            }

        }
    }
}