@import "../../../styles/global.scss";

.container {
  @include mobile {
    width: 90%;
    margin: 0 auto;
  }
}

.wrap {
  padding: 50px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subTitle {
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: #FF4D01;
  padding-bottom: 20px;

  @include mobile {
    font-size: 16px;
  }
}

.title {
  font-weight: 400;
  font-size: 64px;
  text-align: center;
  text-transform: uppercase;
  color: #42525B;
  padding-bottom: 40px;

  @include mobile {
    font-size: 24px;
    padding-bottom: 20px;
  }

  span {
    color: #FF4D01;
  }
}

.resultContent {
  display: flex;
  justify-content: space-around;
  padding-bottom: 20px;
  gap: 50px;
  @include mobile {
    flex-wrap: wrap;
    gap: 0px;
  }

  .resultContentImg {
    width: 444px;
    @include mobile {
      width: 100%;
     
    }

    .img {
      width: 100%;
      height: 591px;
      @include mobile {
        height: 480px;
        // width: 91%;
      }
    }
  }

  .resultContentInfo {
    width: 60%;
    @include mobile {
      width: 80%;
      margin: 0 auto;
    }

    .resultContentTitle {
      font-weight: 400;
      font-size: 56px;
      line-height: 120.9%;
      color: #42525B;
      padding-bottom: 25px;

      @include mobile {
        margin-top: 20px;
        font-size: 35px;
      }
    }
    ul {
      @include mobile {
        margin-top: 20px;
      }
    }
    li {
      font-weight: 400;
      font-size: 26px;
      line-height: 160%;
      color: #42525B;

      @include mobile {
        font-size: 17px;
        line-height: unset;
      }
    }
  }
}

.btnWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @include mobile {
    margin-top: 20px;
  }

  .enjoyBtn {
    width: 220px;
    height: 76px;
    color: #fff;
    text-decoration: none;
    background-color: #FF4D01;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 20px;
    margin-left: 60px;
    @include mobile {
      margin-bottom: 20px;
      margin-left: 0;
    }
  }

  .practicBtn {
    border: 1px solid #FF4D01;
    border-radius: 0px 10px;
    font-weight: 400;
    font-size: 26px;
    line-height: 42px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #FF4D01;
    padding: 16px 32px;
    @include mobile{
      font-size: 20px;
    }
  }
}