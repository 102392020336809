@import "../../../styles/global.scss";

.Information {
  @include mobile {
    width: 100%;
  }
}
.header {
  padding: 50px 0;
  @include mobile {
    padding: 20px 0;
  }
  .title {
    font-weight: 400;
    font-size: 64px;
    text-align: center;
    color: #42525B;
    font-size: 64px;
    @include mobile {
      font-size: 32px;
    }
  }
  p {
    text-align: center;
    color: #FF4D01;
    font-size: 50px;
    @include mobile {
      font-size: 25px;
      margin-top: 20px;
    }
  }
}


.wordsWrap {
  position: relative;

  div {
    font-weight: 400;
    color: #FF4D01;
    position: absolute;
  }

}

.MainTitle {
  @include mobile {
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }
}
.mainInfo {
  padding: 50px 0;
  display: flex;
  justify-content: center;
  gap: 72px;
  @include mobile {
    padding: 0px 0;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  p {
    color: #FF4D01;
    font-size: 50px;
  }
  .img {
    width: 475px;
    @include mobile {
      width: 80%;
      height: 247px;
    }

    img {
      width: 475px;
      height: 440px;
      @include mobile {
        width: 100%;
      height: 247px;
      }
    }
  }

  .mainInfoText {
    text-align: start;
    
    @include mobile {
      width: 80%;
      margin: 0 auto;
    }
    p {
      color: #42525B;
    }
    

    .mainInfoTitle {
      p {
      font-weight: 400;
      font-size: 50px;
      color: #42525B;

      @include mobile {
        margin-top: 0px;
        font-size: 28px;
      }
    }
    }
    ul {
      margin-top: 25px;
      @include mobile {
        margin-left: 30px;
      }
    }
    li {
      font-weight: 400;
      font-size: 26px;
      line-height: 41.6px;
      color: #42525B;

      @include mobile {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
}

.text {
  text-align: start;
  font-weight: 400;
  font-size: 26px;
  color: #42525B;
  display: flex;
  justify-content: center;
  @include mobile{
    font-size: 20px;
    width: 80%;
    margin: 20px auto;
  }
}