@import '../../../styles/global.scss';
.header {
    position: fixed;
    display: none ;
    width: 100%;
    background: white;
    z-index: 20;
    @include mobile {
        display: flex;
        flex-direction: column;

    }
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    .title {
        height: 70px;
        display: flex;
        padding: 0px 38px;
        justify-content: space-between;
        align-items: center;
        img {
            width: 42px;
            height: 29px;
        }
        p {
            color: #FF4D01;
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 700;
        }
        .burger {
            color: #E9C055;
            font-size: 30px;
        }
    }
    .links {
        display: flex;
        flex-direction: column;
        padding: 35px 0px 30px 60px;
        justify-content: center;
        gap: 25px;
        a {
            text-transform: uppercase;
            font-size: 16px;
            text-decoration: none;
        }
        .activeLink {
            padding-bottom: 15px;
            color: #FF4D01;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                left: -15px;
                bottom: 20px;
                width: 5px;
                height: 11px;
                background: #FF4D01;
            }
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0px;
                width: 160px;
                height: 1px;
                background: #FF4D01;
            }
        }
    }
}