@import "../../../styles/global.scss";
.container {
  width: 81%;
  margin: 0 auto;
  @include mobile {
    width: 90%;
  }
}
.howItWork {
  padding-top: 80px;
  text-align: start;
  @include mobile {
    padding-top: 0;
  }
}

.wrap {
  padding: 10px 0 30px;
}

.title {
  color: #42525B;
  font-weight: 400;
  font-size: 64px;
  text-align: center !important;

  @include mobile {
    font-size: 32px;
    margin-top: 20px;
  }
}

.items {
  padding-top: 50px;
  text-align: start;
  @include mobile {
    padding-top: 0;
  }
  .item {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mobile {
      flex-wrap: wrap;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 20px;
    }

    .itemTitle {
      font-weight: 400;
      font-size: 50px;
      line-height: 120.9%;
      color: #FF4D01;
      width: 35%;

      @include mobile {
        text-align: center;
        width: 100%;
        font-size: 23px;
        line-height: 50px;
      }
    }

    .itemText {
      font-weight: 400;
      font-size: 26px;
      line-height: 160%;
      color: #42525B;
      width: 63%;
      @include mobile {
        width: 100%;
        font-size: 18px;
      }
    }
  }
}
.hidden {
  @include mobile {
    display: none;
  }
}
.subTitle {
  font-weight: 400;
  font-size: 38px;
  width: 100%;
  color: #42525B;
  padding: 30px 0;
  text-align: center;
  @include mobile {
    font-size:30px;
    line-height: 45px;
  }

  span {
    color: #FF4D01;
    text-transform: uppercase;
  }
}

.text {
  font-weight: 400;
  font-size: 26px;
  line-height: 160%;
  display: flex;
  width: 100%;
  justify-content: center;
  color: #42525B;
  @include mobile{
    font-size: 20px;
  }

}
.button {
  display: flex;
  justify-content: center;
  a {
    text-decoration: none;
    cursor: pointer;
    padding: 21px 22px;
    color: white;
    border: none;
    background: #FF4D01;
    border-radius: 10px;
    font-size: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    @include mobile {
      margin-top: 10px;
    }
  }
}