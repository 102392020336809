@import '../../styles/global.scss';

.container {
    text-align: start;
    margin-top: 25px;
    h1 {
        text-align: center;
        color: #42525B;
        font-size: 64px;
        font-weight: 400;
        @include mobile {
            font-size: 35px;
        }
    }
    .slide {
        width: 90% !important;
        height: 345px;
        box-shadow: 0px 1px 20px 0px #0000001A;
        
        margin-left: 50px;
        padding: 37px 50px;
        @include mobile {
            width: 300px !important;
            height: 250px;
            padding: 25px 30px;
            margin-left: 20px;
        }
        h3 {
            color: #42525B;
            font-size: 40px;
            font-weight: 400;
            @include mobile {
                font-size: 20px;
            }
        }
        p {
            margin-top: 10px;
            color: #42525B;
            font-size: 16px;
            @include mobile {
                font-size: 16px;
            }
        }
        

    }
    .sliderContainer {
        margin-top: 50px !important;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 50px;
        
    }
    .end {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-top: 100px;
        gap: 50px;
        @include mobile {
            gap: 25px;
            width: 80%;
            margin: 0 auto;
        }
        h3 {
            color: #42525B;
            font-weight: 400;
            font-size: 56px;
            span {
                color: #FF4D01;
            }
           @include mobile {
            font-size: 23px;
           }
        }
        h4 {
                font-size: 56px;
                font-weight: 400;
                color: #FF4D01;
                @include mobile {
                    font-size: 30px;
                }
        }

        a {
            border-radius: 10px;
            padding: 21px 35px;
            cursor: pointer;
            border: none;
            color: white;
            background: #FF4D01;
            margin-bottom: 70px;
            font-size: 20px;
            box-shadow: 0px 4px 4px 0px #00000040;
            text-decoration: none;
            @include mobile {
                padding:  13px 20px ;
                font-size: 20px;
            }
        }
        button {
            border-radius: 10px;
            padding: 21px 23px;
            cursor: pointer;
            border: none;
            color: white;
            background: #FF4D01;
            margin-bottom: 70px;
            font-size: 20px;
            box-shadow: 0px 4px 4px 0px #00000040;

            @include mobile {
                padding:  13px 20px ;
                font-size: 20px;
            }
        }
    }
}
.dotsPag {
    margin-top: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    background: #F6F6F6;
   @include mobile {
    width: 15px;
    height: 15px;
    margin-top: 20px;
   }
}       